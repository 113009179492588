import React from 'react'
import Homepage from './components/Homepage'
import './App.css'
const App = () => {
  return (
    <>
    <Homepage/>
    </>
  )
}

export default App